export const GAME_SOURCE = {
  "PURCHASE": "Zakup",
  "GIFT": "Prezent",
  "PRIZE": "Konkurs"
}

export const BOX_SIZE = {
  "SMALL": "Mały",
  "BIG": "Duży"
}

export const TIME_UNIT = {
  DAY: {label: "dni", value: 1},
  WEEK: {label: "tygodni", value: 7},
  MONTH: {label: "miesięcy", value: 30},
  YEAR: {label: "lat", value: 365}
}

export const WAIT_LIST_TYPE = {
  "YES": "YES",
  "NO": "NO",
  "MAYBE": "MAYBE"
}

export const WAIT_LIST_TYPE_LABELS = {
  "YES": "Chciane",
  "NO": "Niechciane",
  "MAYBE": "Do sprawdzenia"
}

export const GAME_SOURCE_OPTIONS = Object.keys(GAME_SOURCE).map(key => ({value: key, label: GAME_SOURCE[key]}))

export const BOX_SIZE_OPTIONS = Object.keys(BOX_SIZE).map(key => ({value: key, label: BOX_SIZE[key]}))

export const TIME_UNIT_OPTIONS = Object.keys(TIME_UNIT).map(key => TIME_UNIT[key]);

export const WAIT_LIST_TYPE_OPTIONS = Object.keys(WAIT_LIST_TYPE_LABELS).map(key => ({value: key, label: WAIT_LIST_TYPE_LABELS[key]}))