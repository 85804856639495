import {useEffect, useMemo, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {Divider} from "primereact/divider";
import CoverUploader from "../../common/CoverUploader";
import {Checkbox} from "primereact/checkbox";
import {WaitListService} from "../../service/WaitListService";
import {isSmallScreenVertical} from "../../utilities/CommonUtils";
import Select from "react-select";
import {WAIT_LIST_TYPE, WAIT_LIST_TYPE_OPTIONS} from "../../common/Enums";

export default function WaitListForm(props) {
  const emptyGame = useMemo(() => ({
    name: '',
    minPlayers: 0,
    maxPlayers: 0,
    minMinutes: 0,
    maxMinutes: 0,
    price: 0,
    remarks: '',
    played: false,
    ratingAnia: null,
    ratingTomek: null,
    waitListType: WAIT_LIST_TYPE.MAYBE
  }), []);
  const [game, setGame] = useState(emptyGame);
  const [initialGameName, setInitialGameName] = useState('');

  useEffect(() => {
    if (props.id) {
      WaitListService.get(props.id).then(game => {
        setGame(game);
        setInitialGameName(game.name);
      });
    } else {
      setGame(emptyGame);
    }
  }, [props, emptyGame]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    game.ratingAnia = game.ratingAnia || 0;
    game.ratingTomek = game.ratingTomek || 0;
    WaitListService.save(game).then(response => {
      if (response.ok) {
        props.onSuccessfulSubmit();
      } else {
        response.text().then(error => props.showFailureToast('Gra nie została zapisana. ' + error));
      }
    });
  }

  const handleChange = (event) => {
    const target = event.target;
    let {value, name} = target;
    const _game = {...game};
    _game[name] = value;
    setGame(_game);
  }

  const handleCheckboxChange = (event) => {
    const _game = {...game};
    _game.played = event.checked;
    setGame(_game);
  }

  const handleWaitListTypeChange = (item) => {
    const _game = {...game};
    _game.waitListType = item ? item.value : '';
    setGame(_game);
  }

  const handleReset = () => {
    setGame({...emptyGame});
    props.onClose();
  }

  return (
    <div>
      <h3 className="text-center">{props.id ? 'Edytuj ' + initialGameName : 'Dodaj grę'}</h3>
      <Divider/>
      <div className="d-grid gap-2 align-items-center" style={{gridTemplateRows: "repeat(10, 2.5em)"}}>
        <label htmlFor="name" style={{gridColumn: "1"}}>Tytuł</label>
        <InputText id="name" name="name" value={game.name} onChange={handleChange} style={{gridColumn: "span 2"}}/>
        <label style={{gridColumn: "1"}}>Liczba graczy</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="minPlayers" name="minPlayers" size={3} value={game.minPlayers} onValueChange={handleChange}/>-
          <InputNumber id="maxPlayers" name="maxPlayers" size={3} value={game.maxPlayers} onValueChange={handleChange}/>
        </div>
        <label style={{gridColumn: "1"}}>Czas gry</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="minMinutes" name="minMinutes" size={3} value={game.minMinutes} onValueChange={handleChange}/>-
          <InputNumber id="maxMinutes" name="maxMinutes" size={3} value={game.maxMinutes} onValueChange={handleChange}/>
        </div>
        <label htmlFor="price" style={{gridColumn: "1"}}>Cena</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="price" name="price" size={3} value={game.price} onValueChange={handleChange}/>
        </div>
        <label htmlFor="ratingAnia" style={{gridColumn: "1"}}>Ocena Ani</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="ratingAnia" name="ratingAnia" size={3} value={game.ratingAnia} onValueChange={handleChange}
                       min={1} max={5} minFractionDigits={0} maxFractionDigits={1}/>
        </div>
        <label htmlFor="ratingTomek" style={{gridColumn: "1"}}>Ocena Tomka</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <InputNumber id="ratingTomek" name="ratingTomek" size={3} value={game.ratingTomek} onValueChange={handleChange}
                       min={1} max={5} minFractionDigits={0} maxFractionDigits={1}/>
        </div>
        <label htmlFor="played" style={{gridColumn: "1"}}>Zagrane</label>
        <div className="text-center" style={{gridColumn: isSmallScreenVertical() ? "span 2" : "2"}}>
          <Checkbox id="played" name="played" checked={game.played} onChange={handleCheckboxChange}/>
        </div>
        <label htmlFor="remarks" style={{gridColumn: "1"}}>Komentarze</label>
        <InputText id="remarks" name="remarks" value={game.remarks} onChange={handleChange} style={{gridColumn: "span 2"}}/>
        <label htmlFor="waitListType" style={{gridColumn: "1"}}>Kategoria</label>
        <Select id="waitListType" name="waitListType" style={{gridColumn: "span 2"}} options={WAIT_LIST_TYPE_OPTIONS} defaultValue={WAIT_LIST_TYPE_OPTIONS[2]}
                onChange={handleWaitListTypeChange} value={WAIT_LIST_TYPE_OPTIONS.find(suggestion => suggestion.value === game.waitListType)}
        />
        <div className="d-grid h-100 text-center justify-content-center"
             style={isSmallScreenVertical() ? {gridRow: "span 2", gridColumn: "span 3"} : {gridColumn: "3", gridRow: "2 / 8"}}>
          <img className="mw-100 m-auto cover-shadow" style={{maxHeight: 7 * 2.5 + "em"}} src={`data:image/jpeg;base64,${game.cover}`} alt="Brak okładki"/>
        </div>
        <div className="text-center" style={{gridColumn: "span 3"}}>
          <label htmlFor="cover" className="me-2">Zmień okładkę</label>
          <CoverUploader game={game} setGame={setGame} showFailureToast={props.showFailureToast}/>
        </div>
      </div>
      <Divider/>
      <div className="d-flex gap-2 justify-content-center">
        <Button severity="primary" type="submit" onClick={handleSubmit}>Zapisz</Button>
        <Button severity="secondary" type="reset" onClick={handleReset}>Anuluj</Button>
      </div>
    </div>
  )
}
