import {useEffect, useRef, useState} from 'react';
import GameForm from "./GameForm";
import {Tooltip} from 'react-tooltip';
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {FilterMatchMode, FilterService} from "primereact/api";
import {Panel} from "primereact/panel";
import {ToggleButton} from "primereact/togglebutton";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import AuthWrapper from "../../common/AuthWrapper";
import {MultiSelect} from "primereact/multiselect";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {BOX_SIZE, GAME_SOURCE, TIME_UNIT_OPTIONS} from "../../common/Enums";
import {dateToString, isSmallScreen, isSmallScreenVertical, universalSort, updatePageTitle} from "../../utilities/CommonUtils";
import {Slider} from "primereact/slider";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {Divider} from "primereact/divider";
import {GameService} from "../../service/GameService";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {useSessionStorage} from "primereact/hooks";
import {InputSwitch} from "primereact/inputswitch";
import {Link, useParams} from "react-router-dom";
import {AutoComplete} from "primereact/autocomplete";

export default function GameList() {
  const title = 'Lista gier';
  const toast = useRef(null);
  const {goToId} = useParams();
  const [showMoreFilters, setShowMoreFilters] = useSessionStorage(false, "gamelist.showMoreFilters");
  const [games, setGames] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editedGameId, setEditedGameId] = useState(null);
  FilterService.register("soldGameFilter", dateSold => showSoldFilter === null ? true : showSoldFilter === false ? dateSold === null : dateSold !== null);
  FilterService.register("averageTimeFilter", averageTime => averageTimeFilterFrom === null && averageTimeFilterTo === null ? true : averageTime["0"] >= averageTimeFilterFrom && averageTime["0"] <= averageTimeFilterTo);
  const [globalFilterValue, setGlobalFilterValue] = useSessionStorage('', "gamelist.globalFilterValue");
  const [globalFilterSuggestions, setGlobalFilterSuggestions] = useState([]);
  const [playersCountFilter, setPlayersCountFilter] = useSessionStorage(null, "gamelist.playersCountFilter");
  const [lastPlayFilterValue, setLastPlayFilterValue] = useSessionStorage(0, "gamelist.lastPlayFilterValue");
  const [lastPlayFilterMultiplier, setLastPlayFilterMultiplier] = useSessionStorage(1, "gamelist.lastPlayFilterMultiplier");
  const [ownedSinceFilterFrom, setOwnedSinceFilterFrom] = useSessionStorage('', "gamelist.ownedSinceFilterFrom");
  const [ownedSinceFilterTo, setOwnedSinceFilterTo] = useSessionStorage(dateToString(new Date()), "gamelist.ownedSinceFilterTo");
  const [gameCountFilterFrom, setGameCountFilterFrom] = useSessionStorage(null, "gamelist.gameCountFilterFrom");
  const [gameCountFilterTo, setGameCountFilterTo] = useSessionStorage(null, "gamelist.gameCountFilterTo");
  const [averageTimeFilterFrom, setAverageTimeFilterFrom] = useSessionStorage(null, "gamelist.averageTimeFilterFrom");
  const [averageTimeFilterTo, setAverageTimeFilterTo] = useSessionStorage(null, "gamelist.averageTimeFilterTo");
  const [leaderFilter, setLeaderFilter] = useSessionStorage('', "gamelist.leaderFilter");
  const [specialModesFilter, setSpecialModesFilter] = useSessionStorage(false, "gamelist.specialModesFilter");
  const [showSoldFilter, setShowSoldFilter] = useSessionStorage(false, "gamelist.showSoldFilter");
  const [sortField, setSortField] = useSessionStorage("name", "gamelist.sortField");
  const [sortOrder, setSortOrder] = useSessionStorage(1, "gamelist.sortOrder");
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useSessionStorage({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    dateSold: {value: null, matchMode: "soldGameFilter"},
    minPlayers: {value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO},
    maxPlayers: {value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO},
    lastGame: {value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO},
    ownedSince: {value: null, matchMode: FilterMatchMode.BETWEEN},
    leader: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    gameCount: {value: null, matchMode: FilterMatchMode.BETWEEN},
    averageTime: {value: null, matchMode: "averageTimeFilter"}
  }, "gamelist.filters");
  const toggleableColumns = [
    {field: "minPlayers", header: "Min. graczy", sortable: true, filter: true, align: "center"},
    {field: "maxPlayers", header: "Max. graczy", sortable: true, filter: true, align: "center"},
    {field: "minMinutes", header: "Min. czas", sortable: true, filter: false, align: "center"},
    {field: "maxMinutes", header: "Max. czas", sortable: true, filter: false, align: "center"},
    {field: "averageTime", header: "Śr. czas", sortable: true, filter: true, align: "center"},
    {field: "expansions", header: "Dodatki", sortable: false, filter: false, align: "left", italic: true},
    {field: "lastGame", header: "Ostatnia gra", sortable: true, filter: true, align: "center", italic: true},
    {field: "gameCount", header: isSmallScreen() ? "Gry" : "Rozgrywki", sortable: true, filter: true, align: "center", italic: true},
    {field: "leader", header: "Lider", sortable: true, filter: false, align: "center", italic: true},
    {field: "gameSource", header: "Sposób pozyskania", sortable: true, filter: false, align: "center", italic: true},
    {field: "ownedSince", header: "Data pozyskania", sortable: true, filter: true, align: "center", italic: true},
    {field: "dateSold", header: "Data sprzedaży", sortable: true, filter: true, align: "center", italic: true},
    {field: "boxSize", header: "Rozmiar pudełka", sortable: true, filter: false, align: "center", italic: true},
    {field: "totalCost", header: "Wartość", sortable: true, filter: false, align: "center", italic: true},
    {field: "costOfPlay", header: "Wartość/grę", sortable: true, filter: false, align: "center", italic: true}
  ]
  const defaultVisibleColumns = ["minPlayers", "maxPlayers", "lastGame", "gameCount", "leader"];
  const smallScreenVisibleColumns = ["gameCount"];
  const [visibleColumns, setVisibleColumns] = useSessionStorage(toggleableColumns.filter(column => {
    const columnSet = isSmallScreen() ? smallScreenVisibleColumns : defaultVisibleColumns;
    return columnSet.indexOf(column.field) !== -1;
  }), "gamelist.visibleColumns");
  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = toggleableColumns.filter(column => selectedColumns.some((selectedColumn) => selectedColumn.field === column.field));
    if (specialModesFilter) {
      const gameModeLabelColumnIndex = orderedSelectedColumns.findIndex(column => gameModeRelatedFields.indexOf(column.field) !== -1);
      if (gameModeLabelColumnIndex !== -1) {
        orderedSelectedColumns.splice(gameModeLabelColumnIndex, 0, gameModeLabelColumn);
      } else {
        setSpecialModesFilter(false);
      }
    }
    setVisibleColumns(orderedSelectedColumns);
    event.stopPropagation();
  };
  const gameModeLabelColumn = {field: "gameModeLabel", header: "Tryby", sortable: false, filter: false, align: "center"};
  const gameModeRelatedFields = ["lastGame", "gameCount", "leader"];
  const minGameCount = useRef(null);
  const maxGameCount = useRef(null);
  const [leadersOptions, setLeaderOptions] = useState([]);
  const datatable = useRef(null);

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  useEffect(() => {
    if (!showForm) {
      GameService.getAll().then(data => {
        setGames(data);
        minGameCount.current = Math.min(...data.map(game => game.gameModeDetails.STANDARD.gameCount));
        maxGameCount.current = Math.max(...data.map(game => game.gameModeDetails.STANDARD.gameCount));
      });
    }
  }, [showForm]);

  useEffect(() => {
    const _leadersOptions = games.filter(game => showSoldFilter === false ? game.dateSold === null : showSoldFilter === true ? game.dateSold !== null : true)
      .map(game => game.gameModeDetails.STANDARD.leader)
      .filter(leader => leader)
      .map(leader => leader.substring(0, leader.indexOf('(')))
      .filter((value, index, array) => array.indexOf(value) === index);
    setLeaderOptions(_leadersOptions);
  }, [games, showSoldFilter]);

  useEffect(() => {
    if (goToId) {
      datatable.current.filter(goToId, "id", "equals");
    }
  }, [goToId]);

  useEffect(() => {
    if (datatable.current !== null) {
      setResultCount(datatable.current.getTable().querySelectorAll('tbody.p-datatable-tbody tr:not(.p-datatable-emptymessage)').length);
    }
  }, [games]);

  const onGlobalFilterChange = (value) => {
    let _filters = {...filters};

    _filters['global'].value = value.trim();

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const performRemove = (id) => {
    GameService.delete(id).then(response => {
      if (response.ok) {
        let _games = [...games].filter(game => game.id !== id);
        setGames(_games);
        showSuccessToast('Gra została usunięta.');
      } else {
        response.text().then(error => showFailureToast('Gra nie została usunięta. ' + error));
      }
    });
  };

  const toggleForm = (id, show) => {
    setEditedGameId(id);
    setShowForm(show);
  }

  const coverTooltipTemplate = (game) => {
    return <span className="tooltip-cursor pi pi-image"
                 onMouseOver={(event) => {
                   if (!game.cover) {
                     GameService.getCover(game.id).then(data => {
                       game.cover = data.cover;
                       event.target.setAttribute("data-tooltip-html", `<img class="cover" src="data:image/jpeg;base64,${data.cover}" alt="${game.name + " (brak okładki)"}"/>`)
                     });
                   }
                 }}
                 data-tooltip-id="game-tooltip"
                 data-tooltip-html={`<img class="cover" src="data:image/jpeg;base64,${game.cover}" alt="${game.name + " (brak okładki)"}"/>`}></span>
  }

  const actionsTemplate = (game) => {
    return <AuthWrapper><span className="text-nowrap">
      <Button size="small" severity="primary" className="me-1" icon="pi pi-pencil" onClick={() => toggleForm(game.id, true)}/>
      <Button size="small" severity="danger" className="me-1" icon="pi pi-trash" onClick={() => confirmRemove(game.id, game.name)}/>
    </span></AuthWrapper>
  }

  const expansionsTemplate = (game) => {
    return game.expansions.join(", ");
  }

  const gameSourceTemplate = (game) => {
    return GAME_SOURCE[game.gameSource];
  }

  const boxSizeTemplate = (game) => {
    return BOX_SIZE[game.boxSize];
  }

  const gameModeDetailsTemplate = (game, prop, defaultValue, tooltip) => {
    if (!specialModesFilter || (!game.gameModeDetails.COOP && !game.gameModeDetails.CAMPAIGN)) {
      return <span className={tooltip ? "tooltip-cursor " : ""} data-tooltip-id="game-tooltip" data-tooltip-content={tooltip}>
        <Link to={"/history/go/" + game.id} className="go-to-history-link">{game[prop] || defaultValue}</Link>
      </span>;
    }
    return <table className="m-auto">
      <tbody>
      <tr>
        <td><span className={tooltip ? "tooltip-cursor" : ""} data-tooltip-id="game-tooltip" data-tooltip-content={tooltip}>
          <Link to={"/history/go/" + game.id}
                className="go-to-history-link">{game.gameModeDetails.STANDARD[prop] || defaultValue}</Link>
        </span></td>
      </tr>
      {game.gameModeDetails.COOP &&
        <tr>
          <td><Link to={"/history/go/" + game.id} className="go-to-history-link">{game.gameModeDetails.COOP[prop] || defaultValue}</Link></td>
        </tr>
      }
      {game.gameModeDetails.CAMPAIGN &&
        <tr>
          <td><Link to={"/history/go/" + game.id} className="go-to-history-link">{game.gameModeDetails.CAMPAIGN[prop] || defaultValue}</Link></td>
        </tr>
      }
      </tbody>
    </table>;
  };

  const gameModeLabelTemplate = (game) => gameModeDetailsTemplate(game, "label", "Standard");

  const lastGameTemplate = (game) => gameModeDetailsTemplate(game, "lastGame", '\xA0');

  const gameCountTemplate = (game) => {
    let tooltip;
    if (new Date(game.ownedSince).getFullYear() < 2022) {
      tooltip = "Do gier posiadanych przed 2022 rokiem doliczamy 5 rozgrywek";
    }
    return gameModeDetailsTemplate(game, "gameCount", 0, tooltip);
  };

  const leaderTemplate = (game) => gameModeDetailsTemplate(game, "leader", '\xA0');

  const averageTimeTemplate = (game) => {
    let tooltip = Object.entries(game.averageTime).map(([k, v]) => (k === "0" ? "\xA0Ogólny" : k + " graczy") + ": " + v).join("<br/>");
    return <span className="tooltip-cursor" data-tooltip-id="time-tooltip" data-tooltip-html={tooltip}>
      {game.averageTime["0"] || ""}
    </span>
  }

  const customBodyTemplates = {
    "expansions": expansionsTemplate,
    "gameSource": gameSourceTemplate,
    "boxSize": boxSizeTemplate,
    "gameModeLabel": gameModeLabelTemplate,
    "lastGame": lastGameTemplate,
    "gameCount": gameCountTemplate,
    "leader": leaderTemplate,
    "averageTime": averageTimeTemplate
  };

  const headerTemplate = () => {
    return <>
      <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
        <h3>{title} ({resultCount})</h3>
        <div className="d-flex justify-content-evenly">
          <AuthWrapper><Button className="me-2" icon="pi pi-plus-circle" tooltip="Dodaj grę" tooltipOptions={{position: "bottom"}}
                               onClick={() => toggleForm(null, true)}/></AuthWrapper>
          <MultiSelect value={visibleColumns} options={toggleableColumns} optionLabel="header" onChange={onColumnToggle}
                       dropdownIcon="pi pi-sliders-v" className="me-2 icon-only" tooltip="Kolumny" tooltipOptions={{position: "bottom"}}/>
          <ToggleButton className="filter-toggle me-2" onLabel="" offLabel="" onIcon="pi pi-filter" offIcon="pi pi-filter"
                        tooltip="Dodatkowe filtrowanie" tooltipOptions={{position: "bottom"}}
                        checked={showMoreFilters} onChange={event => setShowMoreFilters(event.value)}/>
          <span className="p-input-icon-left">
            <i className="pi pi-search" style={{zIndex: 1}}/>
            <AutoComplete value={globalFilterValue} onChange={event => onGlobalFilterChange(event.target.value)} placeholder="Szukaj..."
                          inputClassName="w-100" inputStyle={{paddingLeft: "2.5rem"}} suggestions={globalFilterSuggestions}
                          completeMethod={event => setGlobalFilterSuggestions(games.map(game => game.name).filter(game => event.query ? game.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 : true))}/>
            <i className="btn-close end-0 pt-0 pe-3" hidden={!globalFilterValue} onClick={() => onGlobalFilterChange("")}/>
          </span>
        </div>
      </div>
    </>
  }

  const closeForm = () => toggleForm(null, false);
  const showSuccessToast = (message) => toast.current.show({severity: 'success', summary: 'Sukces!', detail: message});
  const showFailureToast = (message) => toast.current.show({severity: 'error', summary: 'Błąd!', detail: message});

  const confirmRemove = (id, name) => {
    confirmDialog({
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
      message: 'Czy na pewno usunąć ' + name + '?',
      header: 'Potwierdź usuwanie',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => performRemove(id)
    });
  };

  const onPlayersCountFilterChange = value => {
    setPlayersCountFilter(value);
    let _filters = {...filters};
    _filters['minPlayers'].value = value;
    _filters['maxPlayers'].value = value;
    setFilters(_filters);
  };

  const onLastPlayFilterChange = (value, multiplier) => {
    setLastPlayFilterValue(value);
    setLastPlayFilterMultiplier(multiplier);
    const filterDate = new Date();
    filterDate.setDate(filterDate.getDate() - value * multiplier);
    let _filters = {...filters};
    _filters['lastGame'].value = value === 0 ? null : dateToString(filterDate);
    setFilters(_filters);
  };

  const onOwnedSinceFilterChange = (from, to) => {
    setOwnedSinceFilterFrom(from);
    setOwnedSinceFilterTo(to);
    let _filters = {...filters};
    _filters['ownedSince'].value = [from, to];
    setFilters(_filters);
  };

  const onGameCountFilterChange = (from, to) => {
    const reset = from === null && to === null;
    const min = Math.min(from || minGameCount.current, to || maxGameCount.current);
    const max = Math.max(from || minGameCount.current, to || maxGameCount.current);
    setGameCountFilterFrom(reset ? null : min);
    setGameCountFilterTo(reset ? null : max);
    let _filters = {...filters};
    _filters['gameCount'].value = reset ? null : [min, max];
    setFilters(_filters);
  };

  const onAverageTimeFilterChange = (from, to) => {
    const reset = from === null && to === null;
    const min = Math.min(from || 0, to || 600);
    const max = Math.max(from || 0, to || 600);
    setAverageTimeFilterFrom(reset ? null : min);
    setAverageTimeFilterTo(reset ? null : max);
    let _filters = {...filters};
    _filters['averageTime'].value = reset ? null : [min, max];
    setFilters(_filters);
  };

  const onLeaderFilterChange = value => {
    setLeaderFilter(value);
    let _filters = {...filters};
    _filters['leader'].value = value;
    setFilters(_filters);
  };

  const onSpecialModesFilterChange = (value) => {
    setSpecialModesFilter(value);
    if (value) {
      let _visibleColumns = [...visibleColumns];
      gameModeRelatedFields.forEach(field => {
        if (!_visibleColumns.some(column => column.field === field)) {
          _visibleColumns.push(toggleableColumns.find(column => column.field === field));
        }
      });
      _visibleColumns = toggleableColumns.filter(column => _visibleColumns.some((selectedColumn) => selectedColumn.field === column.field));
      _visibleColumns.splice(_visibleColumns.findIndex(column => column.field === "lastGame"), 0, gameModeLabelColumn);
      setVisibleColumns(_visibleColumns);
    } else {
      let _visibleColumns = [...visibleColumns];
      _visibleColumns = _visibleColumns.filter(column => column.field !== gameModeLabelColumn.field);
      setVisibleColumns(_visibleColumns);
    }
  };

  const onShowSoldFilterChange = (value) => {
    setShowSoldFilter(value);
    let _filters = {...filters};
    _filters['dateSold'].value = value;
    setFilters(_filters);
  };

  const resetAdvancedFilters = () => {
    onPlayersCountFilterChange(null);
    onLastPlayFilterChange(0, 1);
    onOwnedSinceFilterChange('', dateToString(new Date()));
    onLeaderFilterChange('');
    onGameCountFilterChange(null, null);
    onSpecialModesFilterChange(false);
    onShowSoldFilterChange(false);
    onAverageTimeFilterChange(null, null);
  }

  const resolveSortFunction = column => {
    if (gameModeRelatedFields.indexOf(column.field) !== -1) {
      return event => {
        event.data.sort((left, right) => universalSort(left[column.field], right[column.field], event.order));
        return event.data;
      };
    }
    if (column.field === "averageTime") {
      return event => {
        event.data.sort((left, right) => universalSort(left[column.field]["0"], right[column.field]["0"], event.order));
        return event.data;
      };
    }
    return null;
  };

  const advancedFilters = <div className="d-flex align-items-center gap-3 horizontally-scrollable">
    <span>Gracze</span>
    <span>
      <InputNumber inputClassName="text-center" size={4} inputStyle={{height: "2rem"}} min={1} max={12}
                   value={playersCountFilter} onChange={event => onPlayersCountFilterChange(event.value)}/>
      <Slider min={1} max={12} value={playersCountFilter} onChange={event => onPlayersCountFilterChange(event.value)}/>
    </span>
    <Divider layout="vertical" className="m-0"/>
    <span>Grane dawniej niż</span>
    <span className="d-flex align-items-center">
      <InputNumber inputClassName="text-center" size={2} inputStyle={{height: "2rem"}} value={lastPlayFilterValue}
                   onChange={event => onLastPlayFilterChange(event.value, lastPlayFilterMultiplier)}/>
      <Dropdown options={TIME_UNIT_OPTIONS} style={{height: "2rem", lineHeight: "1rem"}} value={lastPlayFilterMultiplier}
                onChange={event => onLastPlayFilterChange(lastPlayFilterValue, event.value)}/>
    </span>
    <Divider layout="vertical" className="m-0"/>
    <span>Grane</span>
    <span>
      <span className="d-flex align-items-center">
        <InputNumber inputClassName="text-center" size={2} inputStyle={{height: "2rem"}} min={minGameCount.current} max={maxGameCount.current}
                     value={gameCountFilterFrom} onValueChange={event => onGameCountFilterChange(event.value, gameCountFilterTo)}/>
        -
        <InputNumber inputClassName="text-center" size={2} inputStyle={{height: "2rem"}} min={minGameCount.current} max={maxGameCount.current}
                     value={gameCountFilterTo} onValueChange={event => onGameCountFilterChange(gameCountFilterFrom, event.value)}/>
      </span>
      <Slider min={minGameCount.current} max={maxGameCount.current} range={true} value={[gameCountFilterFrom, gameCountFilterTo]}
              onChange={event => onGameCountFilterChange(event.value[0], event.value[1])}/>
    </span>
    <span>razy</span>
    <Divider layout="vertical" className="m-0"/>
    <span>Śr. czas</span>
    <span>
      <span className="d-flex align-items-center">
        <InputNumber inputClassName="text-center" size={2} inputStyle={{height: "2rem"}} min={0} max={600}
                     value={averageTimeFilterFrom} onValueChange={event => onAverageTimeFilterChange(event.value, averageTimeFilterTo)}/>
        -
        <InputNumber inputClassName="text-center" size={2} inputStyle={{height: "2rem"}} min={0} max={600}
                     value={averageTimeFilterTo} onValueChange={event => onAverageTimeFilterChange(averageTimeFilterFrom, event.value)}/>
      </span>
      <Slider min={0} max={600} range={true} value={[averageTimeFilterFrom, averageTimeFilterTo]}
              onChange={event => onAverageTimeFilterChange(event.value[0], event.value[1])}/>
    </span>
    <span>min</span>
    <Divider layout="vertical" className="m-0"/>
    <span>Lider</span>
    <Dropdown options={leadersOptions} style={{height: "2rem", lineHeight: "1rem"}} value={leaderFilter}
              onChange={event => onLeaderFilterChange(event.value)} showClear={leaderFilter !== ''}/>
    <Divider layout="vertical" className="m-0"/>
    <span>Pozyskane</span>
    <span className="d-flex align-items-center">
      <input type="date" value={ownedSinceFilterFrom} onChange={event => onOwnedSinceFilterChange(event.target.value, ownedSinceFilterTo)}/>
      -
      <input type="date" value={ownedSinceFilterTo} onChange={event => onOwnedSinceFilterChange(ownedSinceFilterFrom, event.target.value)}/>
    </span>
    <Divider layout="vertical" className="m-0"/>
    <span>Tryby specjalne</span>
    <InputSwitch checked={specialModesFilter} onChange={event => onSpecialModesFilterChange(event.value)}/>
    <Divider layout="vertical" className="m-0"/>
    <span>Sprzedane</span>
    <TriStateCheckbox value={showSoldFilter} onChange={event => onShowSoldFilterChange(event.value)}/>
    <Divider layout="vertical" className="m-0"/>
    <Button icon="pi pi-filter-slash" label="Resetuj" onClick={resetAdvancedFilters} className="overflow-visible"/>
  </div>;

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content"}}}>
      <Toast ref={toast} position="center" className="text-center"/>
      <ConfirmDialog/>
      <Dialog visible={showForm} resizable={false} showHeader={false} onHide={() => setShowForm(false)}>
        <GameForm id={editedGameId} onClose={closeForm} showFailureToast={showFailureToast} onSuccessfulSubmit={() => {
          closeForm();
          showSuccessToast('Zmiany zostały zapisane.');
        }}/>
      </Dialog>
      <Tooltip id="game-tooltip" delayShow={500} place="right" className="tooltip-content"/>
      <Tooltip id="time-tooltip" delayShow={500} place="right" className="tooltip-content"/>
      <DataTable ref={datatable} className="flex-grow-1" value={games} filters={filters} globalFilterFields={['name']}
                 onValueChange={(dataArray) => setResultCount(dataArray.length)}
                 sortField={sortField} sortOrder={sortOrder}
                 emptyMessage="Brak wyników."
                 scrollable scrollHeight="flex"
                 loading={games.length === 0} loadingIcon={<DataLoadingIndicator/>}
                 stripedRows
                 rowClassName={game => {
                   return {"soldGameRow": game.dateSold !== null}
                 }}
                 header={showMoreFilters ? advancedFilters : null}
                 onSort={event => {
                   setSortField(event.sortField);
                   setSortOrder(event.sortOrder);
                 }}>
        <Column field="cover" align="center" body={coverTooltipTemplate} hidden={isSmallScreen()}/>
        <Column field="name" header="Tytuł" sortable/>
        {visibleColumns.map(column =>
          <Column key={column.field} field={column.field} header={column.header} align={column.align}
                  sortable={column.sortable} filter={column.filter} showFilterMenu={false}
                  body={customBodyTemplates[column.field]} bodyClassName={column.italic ? "fst-italic" : ""}
                  sortFunction={resolveSortFunction(column)}
          />
        )}
        <Column body={actionsTemplate}/>
      </DataTable>
    </Panel>
  </>
}
